((d, undefined) => {

  const validateImage = (file, maxSize, minWidth, minHeight) => {
    let reader = new FileReader()
    return new Promise((resolve, reject) => {
      // Test the image formats first
      const allowedFileExtensions = ['jpg', 'jpeg', 'png', 'JPG', 'JPEG', 'PNG']

      let goodImageExtension = (allowedFileExtensions.includes(file.name.split('.').pop()))

      if (!goodImageExtension) {
        d.querySelector('#imageErrors').textContent = 'Invalid format'
        reject(new DOMException('Invalid format.'))
        return
      }

      // Test the image for filesize next.
      let goodImageSize = (file.size <= parseInt(maxSize))

      if (!goodImageSize) {
        d.querySelector('#imageErrors').textContent = 'Too big! (< 2mb only.)'
        reject(new DOMException('Too big!'))
        return
      }


      // Test the image for dimensions last.
      let image = new Image()

      reader.onerror = () => {
        reader.abort()
        reject(new DOMException('Problem parsing file dimensions.'))
        return
      }

      reader.onload = (e) => { 		// Validate height/width
        image.src = e.target.result // Set base64 source returned by FileReader API.
        image.onload = (e) => {

          let [isImageSquare = false] = [(image.height === image.width)]

          if (!isImageSquare) {
            d.querySelector('#imageErrors').textContent = 'Must be a square!'

            reject(new DOMException('Must be a square!'))
            return
          }

          let [goodImageDimensions = false] = [(image.height >= minHeight && image.width >= minWidth)]

          if (!goodImageDimensions) {
            d.querySelector('#imageErrors').textContent = 'Must be 1024x1024px!'

            reject(new DOMException('Must be 1024x1024px!'))
            return
          }

          let goodImage = (goodImageDimensions && goodImageSize && goodImageExtension)
          resolve(goodImage)
        }
      }
      reader.readAsDataURL(file)
    })
  }

  /* Upload to cloudinary */
  const CLOUDINARY_BASE_URL = 'https://api.cloudinary.com/v1_1/redgoose/upload'
  const CLOUDINARY_UPLOAD_PRESET = 'app-icon'

  const imagePreview = d.querySelector('#imgPreview')
  const fileUpload = d.querySelector('input#fileUpload')

  fileUpload?.addEventListener('change', (event) => {

    const file = event.target.files[0]

    validateImage(file, fileUpload.getAttribute('data-max-size'), fileUpload.getAttribute('data-min-width'), fileUpload.getAttribute('data-min-height'))
      .then((result) => {

        if (!result) return

        const formData = new FormData()

        formData.append('file', file)
        formData.append('upload_preset', CLOUDINARY_UPLOAD_PRESET)

        return axios({
          url: CLOUDINARY_BASE_URL,
          method: 'POST',
          header: {
            'Content-Type': 'application/x-www-form-urlencoded'
          },
          data: formData
        }).then((res) => {
          d.querySelector('input#appIconPath').value = res.data.secure_url
          return imagePreview.style.backgroundImage = `url(${res.data.secure_url})`
        }).then(url => {
          d.querySelector('form#CloudinaryAssetPath').submit() // Keep it simple.
        }).catch((err) => {
          console.log(err)
        });

      }).catch(err => console.log(err))
  })

})(document)


