((w, d, axios) => {
  const views = new Set(['/', '/pricing', '/features', '/examples', '/faq', '/developers'])

  if (!(views.has(w.location.pathname) || (w.location.pathname).includes('/features/'))) return

  let butthurt = false // For folks who want to disable our marketing widgets like this.

  if (butthurt) return

  const fomo = [...d.querySelector('div.fomo').children]

  d.querySelector('div.fomo').classList.add('popper')

  const hn = (url) => (new URL(url)).hostname

  const lollipop = (order) => {
    [...fomo[0].children].map(each => each.style.display = `none`)

    let address = order.website.trim()

    address = address.startsWith('https://') ? address : address.startsWith('http://') ? address.replace('http://', 'https://') : `https://${address}`

    const hostname = hn(address)

    d.querySelector(`div.${order.platform}`).style.display = `block`
    fomo[1].children[0].children[0].children[0].innerHTML = ` ${hostname?.replace('www.', '')}`
    fomo[1].children[0].children[0].children[1].innerHTML = ` ${order.country}`
    fomo[1].children[0].children[0].children[2].innerHTML = ` ${order.platform === 'combo' ? 'a' : 'an'} ${order.platform}`
    fomo[1].children[0].children[1].innerHTML = order.time
  }

  let orders = []
  let fomoizer = {}

  const replenishOrders = () => axios.get('/fomo').then(response => { orders = Array.from(response.data.orders) }).catch(err => console.log(err))

  if (orders.length === 0) replenishOrders()

  setTimeout(() => {
    const order = orders.pop()

    lollipop(order) // Display first client.

    fomoizer = setInterval(() => {
      const order = orders.pop()

      if (butthurt) clearTimeout(fomoizer)

      if (orders.length !== 0) lollipop(order)
      if (orders.length === 0) replenishOrders()
    }, 10000) // ← Matches animation duration timings on fomo.css.
  }, 16000) // ← Matches animation delay on fomo.css

  const close = d.getElementById('close')

  close.addEventListener('click', (event) => {
    butthurt = true
    d.querySelector('div.fomo').classList.remove('popper')
    d.querySelector('div.fomo').remove()
  })
})(window, document, axios)
