((d, w, axios, undefined) => {
  if (!(w.location.pathname.includes('thanks'))) return

  const loader = d.querySelector('div.loader')

  if (w.location.pathname.includes('thanks')) loader.setAttribute('style', 'visibility: visible;')

  const ordernumber = `${w.location.href}`.split('/').pop()

  let butthurt = false

  const gooseSpeak = d.getElementById('gooseSpeak')
  const iosAppRepoElement = d.getElementById('iosAppRepo')
  const androidAppRepoElement = d.getElementById('androidAppRepo')
  const orderStatus = d.getElementById('orderStatus')

  const urls = () => axios.get(`/urls/${ordernumber}`).then(urls => {
    switch (urls.data.buildStatus) {
      case 'new':
        gooseSpeak.innerHTML = 'Your apps will be processed shortly.'
        break
      case 'queued':
        gooseSpeak.innerHTML = 'Your apps are on the queue now. Yay!'
        orderStatus.innerHTML = 'Queued'
        break
      case 'inprogress':
        gooseSpeak.innerHTML = 'Hold tight! App conversion in progress.'
        orderStatus.innerHTML = 'In progress'
        break
      case 'paused':
        gooseSpeak.innerHTML = 'Ack! The conversion process was paused.'
        orderStatus.innerHTML = 'Paused'
        butthurt = true
        break
      case 'completed':
        gooseSpeak.innerHTML = '💥 Bam! 💥 Your app(s) are ready!'
        orderStatus.innerHTML = 'Completed'
        printOrderForApps(urls)
        break
      case 'failed':
        orderStatus.innerHTML = 'Failed'
        gooseSpeak.innerHTML = '😨 Conversion has failed for some reason. 😨'
        butthurt = true
        break
      default:
        break
    }
  }).catch(err => console.log(err))

  const printOrderForApps = (urls) => {
    switch (urls.data.platform) {
      case 'ios':
        if (urls.data.iOSSwiftRepoUrl) {
          iosAppRepoElement.innerHTML = `<a href=${urls.data.iOSSwiftRepoUrl} rel="nofollow noreferrer noopener">${urls.data.iOSSwiftRepoUrl}</a>`
          butthurt = true
        }
        break
      case 'android':
        if (urls.data.androidKotlinRepoUrl) {
          androidAppRepoElement.innerHTML = `<a href=${urls.data.androidKotlinRepoUrl} rel="nofollow noreferrer noopener">${urls.data.androidKotlinRepoUrl}</a>`
          butthurt = true
        }
        break
      case 'combo':
        if (urls.data.iOSSwiftRepoUrl && urls.data.androidKotlinRepoUrl) {
          iosAppRepoElement.innerHTML = `<a href=${urls.data.iOSSwiftRepoUrl} target="_blank" rel="nofollow noreferrer noopener">${urls.data.iOSSwiftRepoUrl}</a>`
          androidAppRepoElement.innerHTML = `<a href=${urls.data.androidKotlinRepoUrl} target="_blank" rel="nofollow noreferrer noopener">${urls.data.androidKotlinRepoUrl}</a>`
          butthurt = true
        }
        break
      default:
        console.log('No platform specified')
        break
    }
  }

  const realizeOrder = setInterval(() => {
    urls()
    // execute(await urls())
    if (butthurt) {
      clearTimeout(realizeOrder)
      loader.setAttribute('style', 'visibility: hidden;')
    }
  }, 2000) // ← Matches the build delay interval on Agenda.js
})(document, window, axios)