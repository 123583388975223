((d, axios, undefined) => {
  const onGithubPage = (window.location.pathname.match(/\/github\/.*?/)) ? true : false
  if (!onGithubPage) return

  const githubUsernameForm = d.querySelector('form#githubForm')
  const githubUsername = d.querySelector('input#github-username')
  const orderId = d.querySelector('input#orderId').value
  const domainButton = d.querySelector('button.githubButton')

  const domainErrorLabel = d.querySelector('div.githubError') // Use this may be.

  let validUserName = false

  const validateGithubUserName = () => {
    validUserName = aquaRegia.test(githubUsername.value)

    validUserName
      ? githubUsername.setAttribute('Style', 'box-shadow: inset 1px 1px 6px var(--green);')
      : githubUsername.value === ''
        ? githubUsername.setAttribute(
          'Style',
          ''
        )
        : githubUsername.setAttribute('Style', 'box-shadow: inset 1px 1px 6px var(--red);')
    validUserName
      ? (domainErrorLabel.textContent = 'ツ') && (domainErrorLabel.setAttribute('Style', 'color: var(--green);'))
      : githubUsername.value !== ''
        ? (domainErrorLabel.textContent = '❗') && (domainErrorLabel.setAttribute('Style', 'color: var(--red);'))
        : (domainErrorLabel.textContent = '')
    validUserName
      ? domainButton.removeAttribute('disabled') && domainButton.setAttribute('Style', 'color: var(--black)')
      : domainButton.setAttribute('disabled', 'true')

  }

  githubUsername.addEventListener('keyup', validateGithubUserName, false)


  // Check if the Github username is of Organization type or Person type. Only Person types are allowed.
  let validUserType = false

  const validateGithubUserType = async (text) => {
    if (githubUsername.value === '' || githubUsername.value.length > 39) return

    const response = await axios.get(`https://api.github.com/users/${githubUsername.value}`).catch(err => false)

    if (response !== false) validUserType = (response.data?.type === 'User') ? true : false

    validUserType
      ? githubUsername.setAttribute('Style', 'box-shadow: inset 1px 1px 6px var(--green);')
      : githubUsername.value === ''
        ? githubUsername.setAttribute(
          'Style',
          ''
        )
        : githubUsername.setAttribute('Style', 'box-shadow: inset 1px 1px 6px var(--red);')
    validUserType
      ? (domainErrorLabel.textContent = 'ツ') && (domainErrorLabel.setAttribute('Style', 'color: var(--green);'))
      : githubUsername.value !== ''
        ? (domainErrorLabel.textContent = 'Org?') && (domainErrorLabel.setAttribute('Style', 'color: var(--red);'))
        : (domainErrorLabel.textContent = '')
    validUserType
      ? domainButton.removeAttribute('disabled') && domainButton.setAttribute('Style', 'color: var(--black)')
      : domainButton.setAttribute('disabled', 'true')

  }

  let timer
  const waitTime = 500

  githubUsername.addEventListener('keyup', (event) => {
    clearTimeout(timer)
    timer = setTimeout(() => {
      validateGithubUserType()
    }, waitTime)
  }, false)

  const postGithubUsername = () => axios.post('/github', {
    headers: {
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*'
    },
    data: JSON.stringify({
      github: githubUsername.value,
      id: orderId
    })
  }).then(res => {
    if (res.status == 200) {
      window.location.replace(`/upload/${orderId}`)
    } else {
      console.log('Not a successful update', res)
    }
  }).catch(err => console.log(err))

  const aquaRegia = new RegExp(/^[a-z\d](?:[a-z\d]|-(?=[a-z\d])){0,38}$/i) // The GitHub username validation.

  githubUsernameForm.addEventListener('submit', event => {
    event.preventDefault()
    console.log(validUserName, validUserType)
    if (validUserName && validUserType) postGithubUsername()
  }, false)

  const validateGithubUserWithBackButton = () => {
    validateGithubUserName()
    validateGithubUserType()
  }

  setTimeout(validateGithubUserWithBackButton, 1)

})(document, axios, window)
