// To be implemented.
((d, undefined) => {

  if (!(window.location.pathname.match(/\/upgrade\/.*?/))) return

  // Picking up (live) key on production.
  const STRIPE_PUBLIC_KEY = d.querySelector('#paymentForm').dataset.stripe

  const stripe = Stripe(STRIPE_PUBLIC_KEY)

  const elements = stripe.elements()

  const cardNumber = elements.create('cardNumber', {
    classes: {
      base: 'card'
    },
    style: {
      base: {
        iconColor: '#c4f0ff',
        color: '#111',
        fontWeight: 500,
        fontFamily: 'Roboto, Open Sans, Segoe UI, sans-serif',
        fontSize: '16px',
        fontSmoothing: 'antialiased',
        ':-webkit-autofill': {
          color: '#fce883',
        },
        '::placeholder': {
          color: '#aaa',
        },
      },
      invalid: {
        iconColor: '#FF5755',
        color: '#FF5755',
      },
    }
  })

  const expiration = elements.create('cardExpiry', {
    classes: {
      base: 'expiration'
    },
    style: {
      base: {
        iconColor: '#c4f0ff',
        color: '#111',
        fontWeight: 500,
        fontFamily: 'Roboto, Open Sans, Segoe UI, sans-serif',
        fontSize: '16px',
        fontSmoothing: 'antialiased',
        ':-webkit-autofill': {
          color: '#fce883',
        },
        '::placeholder': {
          color: '#aaa',
        },
      },
      invalid: {
        iconColor: '#FF5755',
        color: '#FF5755',
      },
    }
  })

  const cvv = elements.create('cardCvc', {
    classes: {
      base: 'cvv'
    },
    style: {
      base: {
        iconColor: '#c4f0ff',
        color: '#111',
        fontWeight: 500,
        fontFamily: 'Roboto, Open Sans, Segoe UI, sans-serif',
        fontSize: '16px',
        fontSmoothing: 'antialiased',
        ':-webkit-autofill': {
          color: '#fce883',
        },
        '::placeholder': {
          color: '#aaa',
        },
      },
      invalid: {
        iconColor: '#FF5755',
        color: '#FF5755',
      },
    }
  })

  // Mount to DOM.
  cardNumber.mount('#card-number')
  expiration.mount('#card-expiration')
  cvv.mount('#card-cvv')

  // Handle real-time validation errors from the card Element.
  cardNumber.addEventListener('change', (event) => {
    const displayError = d.getElementById('errors');
    (event.error)
      ? displayError.textContent = event.error.message
      : displayError.textContent = 'ツ'
  })

  expiration.addEventListener('change', (event) => {
    const displayError = d.getElementById('errors');
    (event.error)
      ? displayError.textContent = event.error.message
      : displayError.textContent = 'ツ'
  })

  cvv.addEventListener('change', (event) => {
    const displayError = d.getElementById('errors');
    (event.error)
      ? displayError.textContent = event.error.message
      : displayError.textContent = 'ツ'
  })

  /* Figure out the monies. */
  function stripeTokenHandler(token, customerId, pushNotifications, pinchZoomControl, fastlaneDeployer, intrinsicDesignReport, appStorePublishing, deepLinking, extendedWarranty, whiteGloveService) {

    const orderId = window.location.pathname.split(/[\/ ]+/).pop()

    let data = {
      token,
      customerId,
      orderId,
      ...(!licenses.includes('pushNotifications')) && { pushNotifications: pushNotifications },
      ...(!licenses.includes('pinchZoomControl')) && { pinchZoomControl: pinchZoomControl },
      ...(!licenses.includes('fastlaneDeployer')) && { fastlaneDeployer: fastlaneDeployer },
      ...(!licenses.includes('intrinsicDesignReport')) && { intrinsicDesignReport: intrinsicDesignReport },
      ...(!licenses.includes('appStorePublishing')) && { appStorePublishing: appStorePublishing },
      ...(!licenses.includes('deepLinking')) && { deepLinking: deepLinking },
      ...(!licenses.includes('extendedWarranty')) && { extendedWarranty: extendedWarranty },
      ...(!licenses.includes('whiteGloveService')) && { whiteGloveService: whiteGloveService }
    }

    fetch('/upgrade', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(data)
    }).then(res => {
      if (res.status == 200) {
        window.location.replace(`/done/${orderId}`)
      } else {
        console.log('Not a successful submission', res)
      }
    }).catch(err => console.log(err))
  }

  function updateOrderAttributes(attributeName, truthyness) {
    const orderId = window.location.pathname.split(/[\/ ]+/).pop()

    let data = { _id: orderId }

    data[attributeName] = truthyness

    fetch('/attributes', {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(data)
    }).then(res => {
      if (res.status == 200) {
        console.log('Successfully updated order attributes')
      } else {
        console.log('Not a successful submission', res)
      }
    }).catch(err => console.log(err))
  }

  // Handle form submission
  // HTML elements
  const form = d.getElementById('paymentForm')
  const stripeButton = d.getElementById('processPayment')
  const totalValue = d.getElementById('totalValue')
  const pushNotificationsPrice = d.getElementById('pushNotificationsPrice')
  const pinchZoomControlPrice = d.getElementById('pinchZoomControlPrice')
  const fastlaneDeployerPrice = d.getElementById('fastlaneDeployerPrice')
  const intrinsicDesignReportPrice = d.getElementById('intrinsicDesignReportPrice')
  const appStorePublishingPrice = d.getElementById('appStorePublishingPrice')
  const deepLinkingPrice = d.getElementById('deepLinkingPrice')
  const extendedWarrantyPrice = d.getElementById('extendedWarrantyPrice')
  const whiteGloveServicePrice = d.getElementById('whiteGloveServicePrice')

  /* Grab the elements from the DOM */
  const addPushNotifications = d.getElementById('addPushNotifications')
  const addPinchZoomControl = d.getElementById('addPinchZoomControl')
  const addFastlaneDeployer = d.getElementById('addFastlaneDeployer')
  const addIntrinsicDesignReport = d.getElementById('addIntrinsicDesignReport')
  const addAppStorePublishing = d.getElementById('addAppStorePublishing')
  const addDeepLinking = d.getElementById('addDeepLinking')
  const addExtendedWarranty = d.getElementById('addExtendedWarranty')
  const addWhiteGloveService = d.getElementById('addWhiteGloveService')

  // Order values
  // const email = d.getElementById('email').value
  // const platform = d.getElementById('platform').value
  // const appDesc = d.getElementById('description').value
  // const website = d.getElementById('website').value
  // const appName = d.getElementById('appName').value
  const licenses = d.getElementById('licenses').value
  const customerId = d.getElementById('customerId').value
  const priceOfPushNotifications = d.getElementById('priceOfPushNotifications').value
  const priceOfPinchZoomControl = d.getElementById('priceOfPinchZoomControl').value
  const priceOfFastlaneDeployer = d.getElementById('priceOfFastlaneDeployer').value
  const priceOfIntrinsicDesignReport = d.getElementById('priceOfIntrinsicDesignReport').value
  const priceOfAppStorePublishing = d.getElementById('priceOfAppStorePublishing').value
  const priceOfDeepLinking = d.getElementById('priceOfDeepLinking').value
  const priceOfExtendedWarranty = d.getElementById('priceOfExtendedWarranty').value
  const priceOfWhiteGloveService = d.getElementById('priceOfWhiteGloveService').value

  // Fixed price
  let pushNotifications = () => parseInt(d.getElementById('addPushNotifications').getAttribute('data-pushnotifications'))
  let pinchZoomControl = () => parseInt(d.getElementById('addPinchZoomControl').getAttribute('data-pinchzoomcontrol'))
  let fastlaneDeployer = () => parseInt(d.getElementById('addFastlaneDeployer').getAttribute('data-fastlanedeployer'))
  let intrinsicDesignReport = () => parseInt(d.getElementById('addIntrinsicDesignReport').getAttribute('data-intrinsicdesignreport'))
  let appStorePublishing = () => parseInt(d.getElementById('addAppStorePublishing').getAttribute('data-appstorepublishing'))
  let deepLinking = () => parseInt(d.getElementById('addDeepLinking').getAttribute('data-deeplinking'))
  let extendedWarranty = () => parseInt(d.getElementById('addExtendedWarranty').getAttribute('data-extendedwarranty'))
  let whiteGloveService = () => parseInt(d.getElementById('addWhiteGloveService').getAttribute('data-whitegloveservice'))

  let amount = (pushNotifications, pinchZoomControl, fastlaneDeployer, intrinsicDesignReport, appStorePublishing, deepLinking, extendedWarranty, whiteGloveService) => (pushNotifications() + pinchZoomControl() + fastlaneDeployer() + intrinsicDesignReport() + appStorePublishing() + deepLinking() + extendedWarranty() + whiteGloveService())

  const printOrderValue = () => {
    stripeButton.innerText = `Process $${parseInt(amount(pushNotifications, pinchZoomControl, fastlaneDeployer, intrinsicDesignReport, appStorePublishing, deepLinking, extendedWarranty, whiteGloveService)) / 100}`;

  }

  const updateButtonState = () => {
    (parseInt(amount(pushNotifications, pinchZoomControl, fastlaneDeployer, intrinsicDesignReport, appStorePublishing, deepLinking, extendedWarranty, whiteGloveService)) / 100 === 0) ? stripeButton.setAttribute('disabled', 'disabled') : stripeButton.removeAttribute('disabled')
  }

  const printTotalValue = () => {
    totalValue.innerText = `$${parseInt(amount(pushNotifications, pinchZoomControl, fastlaneDeployer, intrinsicDesignReport, appStorePublishing, deepLinking, extendedWarranty, whiteGloveService)) / 100}/-`
  }

  if (!(licenses.includes('pushNotifications'))) {
    addPushNotifications.addEventListener('change', (event) => {
      addPushNotifications.checked ? addPushNotifications.setAttribute('data-pushnotifications', priceOfPushNotifications) : addPushNotifications.setAttribute('data-pushnotifications', 0)
      addPushNotifications.checked ? (pushNotificationsPrice.innerText = `$${parseInt(priceOfPushNotifications) / 100} `) : (pushNotificationsPrice.innerText = ``)
      printTotalValue()
      printOrderValue()
      updateButtonState()

      updateOrderAttributes('pushNotifications', addPushNotifications.checked)
    })
  }

  if (!(licenses.includes('pinchZoomControl'))) {
    addPinchZoomControl.addEventListener('change', (event) => {
      addPinchZoomControl.checked ? addPinchZoomControl.setAttribute('data-pinchzoomcontrol', priceOfPinchZoomControl) : addPinchZoomControl.setAttribute('data-pinchzoomcontrol', 0)
      addPinchZoomControl.checked ? (pinchZoomControlPrice.innerText = `$${parseInt(priceOfPinchZoomControl) / 100} `) : (pinchZoomControlPrice.innerText = ``)
      printTotalValue()
      printOrderValue()
      updateButtonState()

      updateOrderAttributes('pinchZoomControl', addPinchZoomControl.checked)
    })
  }

  if (!(licenses.includes('fastlaneDeployer'))) {
    addFastlaneDeployer.addEventListener('change', (event) => {
      addFastlaneDeployer.checked ? addFastlaneDeployer.setAttribute('data-fastlanedeployer', priceOfFastlaneDeployer) : addFastlaneDeployer.setAttribute('data-fastlanedeployer', 0)
      addFastlaneDeployer.checked ? (fastlaneDeployerPrice.innerText = `$${parseInt(priceOfFastlaneDeployer) / 100} `) : (fastlaneDeployerPrice.innerText = ``)
      printTotalValue()
      printOrderValue()
      updateButtonState()

      updateOrderAttributes('fastlaneDeployer', addFastlaneDeployer.checked)
    })
  }

  if (!(licenses.includes('intrinsicDesignReport'))) {
    addIntrinsicDesignReport.addEventListener('change', (event) => {
      addIntrinsicDesignReport.checked ? addIntrinsicDesignReport.setAttribute('data-intrinsicdesignreport', priceOfIntrinsicDesignReport) : addIntrinsicDesignReport.setAttribute('data-intrinsicdesignreport', 0)
      addIntrinsicDesignReport.checked ? (intrinsicDesignReportPrice.innerText = `+ $${parseInt(priceOfIntrinsicDesignReport) / 100} `) : (intrinsicDesignReportPrice.innerText = ` - `)
      printTotalValue()
      printOrderValue()
      updateButtonState()

      updateOrderAttributes('intrinsicDesignReport', addIntrinsicDesignReport.checked)
    })
  }

  if (!(licenses.includes('appStorePublishing'))) {
    addAppStorePublishing.addEventListener('change', (event) => {
      addAppStorePublishing.checked ? addAppStorePublishing.setAttribute('data-appstorepublishing', priceOfAppStorePublishing) : addAppStorePublishing.setAttribute('data-appstorepublishing', 0)
      addAppStorePublishing.checked ? (appStorePublishingPrice.innerText = `+ $${parseInt(priceOfAppStorePublishing) / 100} `) : (appStorePublishingPrice.innerText = ` - `)
      printTotalValue()
      printOrderValue()
      updateButtonState()

      updateOrderAttributes('appStorePublishing', addAppStorePublishing.checked)
    })
  }

  if (!(licenses.includes('deepLinking'))) {
    addDeepLinking.addEventListener('change', (event) => {
      addDeepLinking.checked ? addDeepLinking.setAttribute('data-deeplinking', priceOfDeepLinking) : addDeepLinking.setAttribute('data-deeplinking', 0)
      addDeepLinking.checked ? (deepLinkingPrice.innerText = `+ $${parseInt(priceOfDeepLinking) / 100} `) : (deepLinkingPrice.innerText = ` - `)
      printTotalValue()
      printOrderValue()
      updateButtonState()

      updateOrderAttributes('deepLinking', addDeepLinking.checked)
    })
  }
  if (!(licenses.includes('extendedWarranty'))) {
    addExtendedWarranty.addEventListener('change', (event) => {
      addExtendedWarranty.checked ? addExtendedWarranty.setAttribute('data-extendedwarranty', priceOfExtendedWarranty) : addExtendedWarranty.setAttribute('data-extendedwarranty', 0)
      addExtendedWarranty.checked ? (extendedWarrantyPrice.innerText = `+ $${parseInt(priceOfExtendedWarranty) / 100} `) : (extendedWarrantyPrice.innerText = ` - `)
      printTotalValue()
      printOrderValue()
      updateButtonState()

      updateOrderAttributes('extendedWarranty', addExtendedWarranty.checked)
    })
  }

  if (!(licenses.includes('whiteGloveService'))) {
    addWhiteGloveService.addEventListener('change', (event) => {
      addWhiteGloveService.checked ? addWhiteGloveService.setAttribute('data-whitegloveservice', priceOfWhiteGloveService) : addWhiteGloveService.setAttribute('data-whitegloveservice', 0)
      addWhiteGloveService.checked ? (whiteGloveServicePrice.innerText = `+ $${parseInt(priceOfWhiteGloveService) / 100} `) : (whiteGloveServicePrice.innerText = ` - `)
      printTotalValue()
      printOrderValue()
      updateButtonState()

      updateOrderAttributes('whiteGloveService', addWhiteGloveService.checked)
    })
  }

  form.addEventListener('submit', (event) => {
    event.preventDefault()
    stripe.createToken(cardNumber).then((result) => {
      if (result.error) {
        // Inform the user if there was an error
        const errorElement = d.getElementById('errors')
        errorElement.textContent = result.error.message
      } else {
        stripeButton.innerText = `Processing…`

        // Send the token to the server
        stripeTokenHandler(result.token, customerId, addPushNotifications.checked, addPinchZoomControl.checked, addFastlaneDeployer.checked, addIntrinsicDesignReport.checked, addAppStorePublishing.checked, addDeepLinking.checked, addExtendedWarranty.checked, addWhiteGloveService.checked)
      }
    })
  })
})(document)
