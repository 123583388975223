((w, d) => {
  if (!(window.location.pathname.match(/\/order\/.*?/))) return
  // TODO: Test for images being available. Else show render not available.
  const loader = d.querySelector('div.loader')

  if (w.location.pathname.includes('order')) {
    loader.setAttribute('style', 'visibility: visible;')
  }
  setTimeout(() => {
    d.getElementById('brand')?.classList.add('hidden')
    d.getElementById('mockups')?.classList.remove('hidden')
  }, 5000)
})(window, document)
