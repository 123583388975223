((d, w, undefined) => {

  if (!(w.location.pathname).endsWith('/upgrade')) return

  const orderNumberInputBox = d.querySelector('input.orderNumber')
  const orderUpgradeButton = d.querySelector('button.orderUpgradeButton')
  const loader = d.querySelector('div.loader')

  const orderNumberError = d.querySelector('div.orderNumberError')
  const appIconDisplayArea = d.querySelector('div#appIconArea')
  const instructionArea = d.querySelector('p#instruction')

  const fetchAppIcons = async (orderNumber) => {
    const apps = await axios.get(`/fetch/paid/app/order/${orderNumber}`)

    instructionArea.innerHTML = `Verify your app on the Red Goose? 👉🏻`

    appIconDisplayArea.innerHTML =
      `
      <table class="upgradeAppsTable xxs ">
        <tr>
        <td class="flex-center"> <div class="app-icon-wrapper flex-center"><img class="app-icon" src="${apps.data.appIconPath}"/></div></td>
        <td> </td> 
        </tr>
        <tr>
        <td class="center-align"><h2> ${apps.data.appName} </h2> </td>
        <td> </td> 
        </tr>

        <tr>
          <td>Order #: </td> 
          <td>${apps.data.orderNumber}</td>
        </tr>
        <tr>
          <td>Platform: </td> 
          <td>${apps.data.platform === 'combo' ? 'Combo (iOS & Android)' : apps.data.platform}</td>
        </tr>
        <tr>
          <td colspan="2"> <hr/> </td>
        </tr>
        <tr>
          <td colspan="2"> <p> <strong>☝🏼 Is this your mobile app with Red Goose? </strong></p> </td>
        </tr>

        <tr>
          <td> <a class="white-text button btn gradient-pinkred flex-center" href="/upgrade/${apps.data.orderNumber}"> Yes, it is! 👍🏻 </a></td>
          <td> <a onclick="window.location.reload()" class="black-text button flex-center" href="/upgrade"> Not my order. </a></td>
        </tr>
      <table>
    `
    loader.setAttribute('style', 'visibility: invisible;')
  }



  const validateOrderNumber = async (e) => {
    e.preventDefault()
    if (orderNumberInputBox.value.trim().length === 0) {
      orderNumberError.textContent = '';
      orderNumberInputBox.setAttribute('style', 'color: var(--black);');
      orderUpgradeButton.setAttribute('disabled', 'true');
      orderUpgradeButton.setAttribute('Style', 'background-image: var(--velvet-linear)');
      orderUpgradeButton.textContent = 'Fetch →';
      return
    }
    const validationResponse = await axios.get(`/validateOrderNumber/${orderNumberInputBox.value.trim()} `)

    if (validationResponse.data.orderNumberValidity) {
      orderNumberError.textContent = '';
      orderNumberInputBox.setAttribute('style', 'color: var(--black);');
      orderUpgradeButton.setAttribute('disabled', 'false');
      orderUpgradeButton.setAttribute('Style', 'background-image: var(--velvet-linear-reversed)');
      orderUpgradeButton.textContent = 'Fetching…';
      loader.setAttribute('style', 'visibility: visible;')

      fetchAppIcons(orderNumberInputBox.value.trim())
    } else {
      orderUpgradeButton.setAttribute('disabled', 'true');
      orderUpgradeButton.setAttribute('Style', 'background-image: var(--gradient)');
      orderNumberInputBox.setAttribute('style', 'color: var(--red);');
      orderNumberError.textContent = '🚫';
    }
  }

  orderNumberInputBox.addEventListener('keyup', validateOrderNumber, false)

})(document, window)
