import { isLength, isEmail, isAscii, isURL } from 'validator'
import Mailcheck from 'mailcheck'

((d, w, undefined) => {
  const views = new Set(['/', '/pricing', '/features', '/examples', '/faq'])

  if (views.has(w.location.pathname)) {
    const domainForm = d.querySelector('form.domainForm')
    const url = d.querySelector('input.url')
    const domainButton = d.querySelector('button.domainButton')
    const loader = d.querySelector('div.loader')

    const domainErrorLabel = d.querySelector('div.domainError') // Use this may be.

    const reserved = ['google.com', 'instagram.com', 'test.com', 'facebook.com', 'youtube.com', 'new.ycombinator.com', 'github.com', 'github.io', 'producthunt.com', 'apple.com', 'twitter.com', 'goose.red', 'microsoft.com']

    let validDomain = false

    const validateDomain = () => {
      validDomain = isURL(url.value, {
        require_valid_protocol: false,
        protocols: ['https', 'http'],
        require_protocol: false
      }) && !((new Set(reserved).has(url.value)) || reserved.some(substring => url.value.includes(substring)))

      validDomain
        ? url.setAttribute('Style', 'box-shadow: inset 1px 1px 6px var(--green);')
        : url.value === ''
          ? url.setAttribute(
            'Style',
            ''
          )
          : url.setAttribute('Style', 'box-shadow: inset 1px 1px 6px var(--red);')
      validDomain
        ? (domainErrorLabel.textContent = 'ツ') && (domainErrorLabel.setAttribute('Style', 'color: var(--green);'))
        : url.value !== ''
          ? (domainErrorLabel.textContent = '🚫') && (domainErrorLabel.setAttribute('Style', 'color: var(--red);'))
          : (domainErrorLabel.textContent = '')
      validDomain
        ? domainButton.removeAttribute('disabled') && domainButton.setAttribute('Style', 'color: var(--black)')
        : domainButton.setAttribute('disabled', 'true') && domainButton.setAttribute('Style', 'background-image: var(--gradient)')
    }

    url.addEventListener('keyup', validateDomain, false)

    domainForm.addEventListener(
      'submit',
      event => {
        loader.setAttribute('style', 'visibility: visible;')
        validDomain = isURL(url.value, {
          require_valid_protocol: false,
          protocols: ['https', 'http'],
          require_protocol: false
        })
        if (!validDomain) event.preventDefault()
      },
      false
    )

    const validateDomainWithBackButton = () => {
      validateDomain()
    }

    setTimeout(validateDomainWithBackButton, 1)
  }

  // leadForm on the /convert page.
  if (w.location.pathname === '/convert') {
    const leadForm = d.querySelector('form#leadForm')
    const userButton = d.querySelector('button#userButton')
    const loader = d.querySelector('div.loader')


    let validAppName = false
    let validWebsite = false
    let validEmail = false

    // App name
    const appName = d.querySelector('input#appName')
    const appNameError = d.querySelector('div#appNameError')

    const reserved = ['test', 'facebook', 'google', 'microsoft', 'producthunt', 'ycombinator', 'hackernews', 'red goose', 'redgoose', 'indiehackers', 'github']

    const trademarked = (appName) => new Set(reserved).has(appName.toLowerCase())

    const validateAppName = event => {
      validAppName =
        isAscii(appName.value) && isLength(appName.value, { min: 3, max: 12 }) && !trademarked(appName.value)

      isAscii(appName.value)
        ? isLength(appName.value, { min: 3, max: 12 })
          ? appName.setAttribute('Style', 'box-shadow: inset 1px 1px 6px var(--green);')
          : appName.value === ''
            ? appName.setAttribute('Style', '')
            : appName.setAttribute('Style', 'box-shadow: inset 1px 1px 6px var(--red);')
        : appName.value === ''
          ? appName.setAttribute('Style', '')
          : appName.setAttribute('Style', 'box-shadow: inset 1px 1px 6px var(--red);')

      isAscii(appName.value)
        ? isLength(appName.value, { min: 3, max: 12 })
          ? (appNameError.textContent = 'ツ') && (appNameError.setAttribute('Style', 'color: var(--black);'))
          : appName.value !== ''
            ? (appNameError.textContent = '(Length 3-12).') && (appNameError.setAttribute('Style', 'color: var(--red);'))
            : (appNameError.textContent = '')
        : appName.value !== ''
          ? (appNameError.textContent = '(ASCII only).') && (appNameError.setAttribute('Style', 'color: var(--red);'))
          : (appNameError.textContent = '')

      !(trademarked(appName.value))
        ? appName.setAttribute('Style', 'box-shadow: inset 1px 1px 6px var(--green);')
        : appName.value === ''
          ? appName.setAttribute('Style', '')
          : appName.setAttribute('Style', 'box-shadow: inset 1px 1px 6px var(--red);')

      !(trademarked(appName.value))
        ? (appNameError.textContent = 'ツ') && (appNameError.setAttribute('Style', 'color: var(--black);'))
        : appName.value !== ''
          ? (appNameError.textContent = 'Reserved') && (appNameError.setAttribute('Style', 'color: var(--red);'))
          : (appNameError.textContent = '')

      // Test the leadForm button.
      validAppName && validWebsite && validEmail
        ? userButton.removeAttribute('disabled')
        : userButton.setAttribute('disabled', 'true')
    }

    appName.addEventListener('keyup', validateAppName, false)

    const validateAppNameWithBackButton = () => {
      validateAppName()
    }

    setTimeout(validateAppNameWithBackButton, 1)

    // Website/domain
    const website = d.querySelector('input#website')
    const websiteError = d.querySelector('div#websiteError')

    const validateDomain = event => {
      validWebsite = isURL(website.value, {
        require_valid_protocol: false,
        protocols: ['https', 'http'],
        require_protocol: false
      })

      validWebsite
        ? website.setAttribute('Style', 'box-shadow: inset 1px 1px 6px var(--green);')
        : website.value === ''
          ? website.setAttribute('Style', '')
          : website.setAttribute('Style', 'box-shadow: inset 1px 1px 6px var(--red);')

      validWebsite
        ? (websiteError.textContent = 'ツ') && (websiteError.setAttribute('Style', 'color: var(--black);'))
        : website.value !== ''
          ? (websiteError.textContent = '🚫') && (websiteError.setAttribute('Style', 'color: var(--red);'))
          : (websiteError.textContent = '')

      // Test the leadForm button.
      validAppName && validWebsite && validEmail
        ? userButton.removeAttribute('disabled')
        : userButton.setAttribute('disabled', 'true')
    }

    website.addEventListener('keyup', validateDomain, false)

    const validateDomainWithBackButton = () => {
      validateDomain()
    }

    setTimeout(validateDomainWithBackButton, 1)

    // Email address validations and spelling assistance
    const email = d.querySelector('input#email')
    const emailError = d.querySelector('div#emailError')
    const fakedomains = new Set(['example.com', 'test.com', 'goose.red'])

    const validateEmail = event => {
      validEmail = isEmail(email.value.trim()) && !fakedomains.has(email.value.trim().split("@")[1])

      validEmail
        ? email.setAttribute('Style', 'box-shadow: inset 1px 1px 6px var(--green);')
        : email.value === ''
          ? email.setAttribute('Style', '')
          : email.setAttribute('Style', 'box-shadow: inset 1px 1px 6px var(--red);')
      validEmail
        ? (emailError.textContent = 'ツ') && (emailError.setAttribute('Style', 'color: var(--black);'))
        : email.value !== ''
          ? (emailError.textContent = '🚫') && (emailError.setAttribute('Style', 'color: var(--red);'))
          : (emailError.textContent = '')

      // Test the leadForm button.
      validAppName && validWebsite && validEmail
        ? userButton.removeAttribute('disabled')
        : userButton.setAttribute('disabled', 'true')
    }

    email.addEventListener('keyup', validateEmail, false)

    const validateEmailWithBackButton = () => {
      validateEmail()
    }

    setTimeout(validateEmailWithBackButton, 1)

    // Assistance on email: Spelling check.
    const assistOnEmail = event => {
      Mailcheck.run({
        email: email.value,
        // domains: domains,                       // optional
        // topLevelDomains: topLevelDomains,       // optional
        // secondLevelDomains: secondLevelDomains, // optional
        // distanceFunction: superStringDistance,  // optional
        suggested: (suggestion) => {
          // console.log(suggestion)
          email.setAttribute('Style', 'box-shadow: inset 1px 1px 6px var(--orange);')
          emailError.textContent = 'Spelled ok?'
        }
      })
    }

    email.addEventListener('keyup', assistOnEmail, false)

    leadForm.addEventListener(
      'submit',
      event => {
        loader.setAttribute('style', 'visibility: visible;')
        if (!validAppName || !validWebsite || !validEmail)
          event.preventDefault()
      },
      false
    )
  }

})(document, window)
