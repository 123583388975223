import isEmail from 'validator/es/lib/isEmail'
import Mailcheck from 'mailcheck'

((d, w, axios, undefined) => {
  const views = new Set(['/developers'])

  if (views.has(w.location.pathname)) {
    const developerForm = d.querySelector('form#developerForm')
    const developerButton = d.querySelector('button#developerButton')
    const loader = d.querySelector('div.loader')

    let validEmail = false

    // Email address validations and spelling assistance
    const email = d.querySelector('input#email')
    const emailError = d.querySelector('div#emailError')
    const fakedomains = new Set(['example.com', 'test.com', 'goose.red'])

    const validateEmail = event => {
      validEmail = isEmail(email.value.trim()) && !fakedomains.has(email.value.trim().split("@")[1])

      validEmail
        ? email.setAttribute('Style', 'box-shadow: inset 1px 1px 6px var(--green);')
        : email.value === ''
          ? email.setAttribute('Style', '')
          : email.setAttribute('Style', 'box-shadow: inset 1px 1px 6px var(--red);')
      validEmail
        ? (emailError.textContent = 'ツ') && (emailError.setAttribute('Style', 'color: var(--black);'))
        : email.value !== ''
          ? (emailError.textContent = '❗') && (emailError.setAttribute('Style', 'color: var(--red);'))
          : (emailError.textContent = '')

      // Test the developerForm button.
      validEmail
        ? developerButton.removeAttribute('disabled')
        : developerButton.setAttribute('disabled', 'true')
    }

    email.addEventListener('keyup', validateEmail, false)

    const validateEmailWithBackButton = () => {
      validateEmail()
    }

    setTimeout(validateEmailWithBackButton, 1)

    // Assistance on email: Spelling check.
    const assistOnEmail = event => {
      Mailcheck.run({
        email: email.value,
        // domains: domains,                       // optional
        // topLevelDomains: topLevelDomains,       // optional
        // secondLevelDomains: secondLevelDomains, // optional
        // distanceFunction: superStringDistance,  // optional
        suggested: (suggestion) => {
          // console.log(suggestion)
          email.setAttribute('Style', 'box-shadow: inset 1px 1px 6px var(--orange);')
          emailError.textContent = 'Spelled ok?'
        }
      })
    }

    email.addEventListener('keyup', assistOnEmail, false)

    developerForm.addEventListener('submit', event => { event.preventDefault() }, false)

    developerButton.addEventListener('click', (event) => {
      loader.setAttribute('style', 'visibility: visible;')

      axios({
        method: 'post',
        url: '/register',
        header: {
          'Content-Type': 'application/json'
        },
        data: {
          email: email.value.trim()
        }
      }).then((res) => {
        d.querySelector('div.cta').innerHTML = `
        <h2> Thank you for your interest in our <strong>Developer Dashboard.<sup>beta</sup></strong> </h2>
        <div class="faded-text">
            <p class="top-gap"> You are at waitlist number <span class="dark-text">${res.data.waitlistNumber.toLocaleString('en-US', { maximumFractionDigits: 2 })}</span> on our queue. We will reach out to you personally in a few days.</p>
            <p class="top-gap"> 
            
              <a class="gray-border left dark-text s" href='/'> ← Go home </a>
              
            </p>
          </div>
        `
        loader.setAttribute('style', 'visibility: hidden;')
      })


    }, false)
  }
})(document, window, axios)
