((w, d, undefined) => {
  w.heap = w.heap || [], heap.load = function (e, t) {
    w.heap.appid = e, w.heap.config = t = t || {};
    var r = d.createElement('script');
    r.type = 'text/javascript', r.async = !0, r.src = `https://cdn.heapanalytics.com/js/heap-${e}.js`;
    var a = d.getElementsByTagName('script')[0];
    a.parentNode.insertBefore(r, a);
    for (var n = function (e) {
      return function () {
        heap.push([e].concat(Array.prototype.slice.call(arguments, 0)))
      }
    }, p = ['addEventProperties', 'addUserProperties', 'clearEventProperties', 'identify', 'resetIdentity', 'removeEventProperty', 'setEventProperties', 'track', 'unsetEventProperty'], o = 0; o < p.length; o++) heap[p[o]] = n(p[o])
  };
  heap.load('346161145')
})(window, document)