((n, w, d, undefined) => {
  const init = () => {
    // Check for service worker support
    if (!('serviceWorker' in n)) return

    // Register the service worker
    n.serviceWorker.register('/dist/swa.bundle.js').then(reg => {
      if (!reg.installing) return

      reg.installing.onstatechange = () => {
        if (reg.installing.state === 'installed') {
          if (n.serviceWorker.controller) {
            console.log('New or updated content is available.');
          } else {
            console.log('Content is now available offline!');
          }
        }
      };
    }).catch(error => {
      console.error('Error during service worker registration:', error)
    })
  }


  d.addEventListener('DOMContentLoaded', init, false)
})(navigator, window, document)