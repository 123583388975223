((d, undefined) => {
  if (!(window.location.pathname.match(/\/order\/.*?/))) return

  // Picking up (live) key on production.
  const STRIPE_PUBLIC_KEY = d.querySelector('#paymentForm').dataset.stripe

  const stripe = Stripe(STRIPE_PUBLIC_KEY)

  const elements = stripe.elements()

  const cardNumber = elements.create('cardNumber', {
    classes: {
      base: 'card'
    },
    style: {
      base: {
        iconColor: '#c4f0ff',
        color: '#111',
        fontWeight: 500,
        fontFamily: 'Roboto, Open Sans, Segoe UI, sans-serif',
        fontSize: '16px',
        fontSmoothing: 'antialiased',
        ':-webkit-autofill': {
          color: '#fce883',
        },
        '::placeholder': {
          color: '#aaa',
        },
      },
      invalid: {
        iconColor: '#FF5755',
        color: '#FF5755',
      },
    }
  })

  const expiration = elements.create('cardExpiry', {
    classes: {
      base: 'expiration'
    },
    style: {
      base: {
        iconColor: '#c4f0ff',
        color: '#111',
        fontWeight: 500,
        fontFamily: 'Roboto, Open Sans, Segoe UI, sans-serif',
        fontSize: '16px',
        fontSmoothing: 'antialiased',
        ':-webkit-autofill': {
          color: '#fce883',
        },
        '::placeholder': {
          color: '#aaa',
        },
      },
      invalid: {
        iconColor: '#FF5755',
        color: '#FF5755',
      },
    }
  })

  const cvv = elements.create('cardCvc', {
    classes: {
      base: 'cvv'
    },
    style: {
      base: {
        iconColor: '#c4f0ff',
        color: '#111',
        fontWeight: 500,
        fontFamily: 'Roboto, Open Sans, Segoe UI, sans-serif',
        fontSize: '16px',
        fontSmoothing: 'antialiased',
        ':-webkit-autofill': {
          color: '#fce883',
        },
        '::placeholder': {
          color: '#aaa',
        },
      },
      invalid: {
        iconColor: '#FF5755',
        color: '#FF5755',
      },
    }
  })

  // Mount to DOM.
  cardNumber.mount('#card-number')
  expiration.mount('#card-expiration')
  cvv.mount('#card-cvv')

  // Handle real-time validation errors from the card Element.
  cardNumber.addEventListener('change', (event) => {
    const displayError = d.getElementById('errors');
    (event.error)
      ? displayError.textContent = event.error.message
      : displayError.textContent = 'ツ'
  })

  expiration.addEventListener('change', (event) => {
    const displayError = d.getElementById('errors');
    (event.error)
      ? displayError.textContent = event.error.message
      : displayError.textContent = 'ツ'
  })

  cvv.addEventListener('change', (event) => {
    const displayError = d.getElementById('errors');
    (event.error)
      ? displayError.textContent = event.error.message
      : displayError.textContent = 'ツ'
  })

  /* Figure out the monies. */
  function stripeTokenHandler(token, email, platform, appDesc, website, appName, couponCode, pushNotifications, pinchZoomControl) {
    const orderId = window.location.pathname.split(/[\/ ]+/).pop()

    let data = {
      token: token,
      email: email,
      platform: platform,
      appDesc: appDesc,
      website: website,
      appName: appName,
      pushNotifications: pushNotifications,
      pinchZoomControl: pinchZoomControl,
      couponCode: couponCode,
      orderId
    }

    fetch('/charge', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(data)
    }).then(res => {
      if (res.status == 200) {
        window.location.replace(`/github/${orderId}`)
      } else {
        console.log('Not a successful submission', res)
      }
    }).catch(err => console.log(err))
  }

  function updateOrderAttributes(attributeName, truthyness) {
    const orderId = window.location.pathname.split(/[\/ ]+/).pop()
    let data = { _id: orderId }

    data[attributeName] = truthyness

    fetch('/attributes', {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(data)
    }).then(res => {
      if (res.status == 200) {
        console.log('Successfully updated order attributes')
      } else {
        console.log('Not a successful submission', res)
      }
    }).catch(err => console.log(err))
  }

  // Handle form submission
  // HTML elements
  const form = d.getElementById('paymentForm')
  const stripeButton = d.getElementById('processPayment')
  const totalValue = d.getElementById('totalValue')
  const pushNotificationsPrice = d.getElementById('pushNotificationsPrice')
  const pinchZoomControlPrice = d.getElementById('pinchZoomControlPrice')

  // const designReportPrice = d.getElementById('designReportPrice')

  const addPushNotifications = d.getElementById('addPushNotifications')
  const addPinchZoomControl = d.getElementById('addPinchZoomControl')

  // const includeDesignReport = d.getElementById('includeDesignReport')

  // Order values
  const email = d.getElementById('email').value
  const platform = d.getElementById('platform').value
  const appDesc = d.getElementById('description').value
  const website = d.getElementById('website').value
  const appName = d.getElementById('appName').value
  const priceOfPushNotifications = d.getElementById('priceOfPushNotifications').value
  const priceOfPinchZoomControl = d.getElementById('priceOfPinchZoomControl').value

  // const priceOfDesignReport = d.getElementById('priceOfDesignReport').value

  // Fixed price
  const basePrice = parseInt(d.getElementById('basePrice').value)

  // Coupon is the only value that can vary depending on the outcome from the server.
  let couponCode = d.getElementById('couponCode')

  let pushNotifications = () => parseInt(d.getElementById('addPushNotifications').getAttribute('data-pushnotifications'))
  let pinchZoomControl = () => parseInt(d.getElementById('addPinchZoomControl').getAttribute('data-pinchzoomcontrol'))


  let discount = () => parseInt(d.getElementById('couponCode').getAttribute('data-couponValue'))

  let amount = (basePrice, discount, pushNotifications, pinchZoomControl) => (basePrice + pushNotifications() + pinchZoomControl()) - discount()

  const printOrderValue = () => {
    stripeButton.innerText = `Process $${parseInt(amount(basePrice, discount, pushNotifications, pinchZoomControl)) / 100}`
  }
  const printTotalValue = () => {
    totalValue.innerText = `$${parseInt(amount(basePrice, discount, pushNotifications, pinchZoomControl)) / 100}`
  }

  addPushNotifications.addEventListener('change', (event) => {
    addPushNotifications.checked ? addPushNotifications.setAttribute('data-pushnotifications', priceOfPushNotifications) : addPushNotifications.setAttribute('data-pushnotifications', 0)
    addPushNotifications.checked ? (pushNotificationsPrice.innerText = `$${parseInt(priceOfPushNotifications) / 100} `) : (pushNotificationsPrice.innerText = ``)
    printTotalValue()
    printOrderValue()

    updateOrderAttributes('pushNotifications', addPushNotifications.checked)
  })

  addPinchZoomControl.addEventListener('change', (event) => {
    addPinchZoomControl.checked ? addPinchZoomControl.setAttribute('data-pinchzoomcontrol', priceOfPinchZoomControl) : addPinchZoomControl.setAttribute('data-pinchzoomcontrol', 0)
    addPinchZoomControl.checked ? (pinchZoomControlPrice.innerText = `$${parseInt(priceOfPinchZoomControl) / 100} `) : (pinchZoomControlPrice.innerText = ``)
    printTotalValue()
    printOrderValue()

    updateOrderAttributes('pinchZoomControl', addPinchZoomControl.checked)
  })


  // Coupon handling
  d.getElementById('applyCouponCode').addEventListener('click', (event) => {
    event.preventDefault()

    const couponString = d.getElementById('couponString').value.trim()

    if (couponString === '') return

    fetch(`/coupon/${couponString}`, { method: 'GET' })
      .then(response => response.json())
      .then(data => {
        if (data.coupon === '0') {
          d.getElementById('couponError').textContent = 'is invalid or expired.'
          d.getElementById('couponCode').value = couponString
          d.getElementById('couponCode').setAttribute('data-couponValue', data.coupon)
          d.getElementById('couponStatus').innerHTML = `<p class="italics red-text" > -</p>`
          printOrderValue()
          printTotalValue()
        } else {
          d.getElementById('couponError').textContent = 'Applied! 🎉'
          d.getElementById('couponCode').value = couponString
          d.getElementById('couponCode').setAttribute('data-couponValue', parseInt(data.coupon))
          d.getElementById('couponStatus').innerHTML = `<p class="italics red-text" > -$${parseInt(data.coupon) / 100}</p> `
          printOrderValue()
          printTotalValue()
        }
      }).catch(err => { console.log(err) })
  }, false)


  form.addEventListener('submit', (event) => {
    event.preventDefault()

    stripe.createToken(cardNumber).then((result) => {
      if (result.error) {
        // Inform the user if there was an error
        const errorElement = d.getElementById('errors')
        errorElement.textContent = result.error.message
      } else {
        stripeButton.innerText = `Processing…`

        // Send the token to the server
        stripeTokenHandler(result.token, email, platform, appDesc, website, appName, couponCode.value, addPushNotifications.checked, addPinchZoomControl.checked)
      }
    })
  })



})(document)
