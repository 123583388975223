import isLength from 'validator/es/lib/isLength'
import isEmail from 'validator/es/lib/isEmail'
import isAscii from 'validator/es/lib/isAscii'
import isURL from 'validator/es/lib/isURL'
import Mailcheck from 'mailcheck'

((d, w, undefined) => {

  if (!(w.location.pathname).includes('/edit/')) return

  // updateOrderForm on the /convert page.
  const updateOrderForm = d.querySelector('form#updateOrderForm')
  const userButton = d.querySelector('button#userButton')
  const loader = d.querySelector('div.loader')


  let validAppName = false
  let validWebsite = false
  let validEmail = false

  // App name
  const appName = d.querySelector('input#appName')
  const appNameError = d.querySelector('div#appNameError')

  const validateAppName = event => {
    validAppName =
      isAscii(appName.value) && isLength(appName.value, { min: 3, max: 12 })

    isAscii(appName.value)
      ? isLength(appName.value, { min: 3, max: 12 })
        ? appName.setAttribute('Style', 'box-shadow: inset 1px 1px 6px var(--green);')
        : appName.value === ''
          ? appName.setAttribute('Style', '')
          : appName.setAttribute('Style', 'box-shadow: inset 1px 1px 6px var(--red);')
      : appName.value === ''
        ? appName.setAttribute('Style', '')
        : appName.setAttribute('Style', 'box-shadow: inset 1px 1px 6px var(--red);')

    isAscii(appName.value)
      ? isLength(appName.value, { min: 3, max: 12 })
        ? (appNameError.textContent = 'ツ') && (appNameError.setAttribute('Style', 'color: var(--black);'))
        : appName.value !== ''
          ? (appNameError.textContent = '(Length 3-12).') && (appNameError.setAttribute('Style', 'color: var(--red);'))
          : (appNameError.textContent = '')
      : appName.value !== ''
        ? (appNameError.textContent = '(ASCII only).') && (appNameError.setAttribute('Style', 'color: var(--red);'))
        : (appNameError.textContent = '')

    // Test the updateOrderForm button.
    validAppName && validWebsite && validEmail
      ? userButton.removeAttribute('disabled')
      : userButton.setAttribute('disabled', 'true')
  }

  appName.addEventListener('keyup', validateAppName, false)

  const validateAppNameWithBackButton = () => {
    validateAppName()
  }

  setTimeout(validateAppNameWithBackButton, 1)

  // Website/domain
  const website = d.querySelector('input#website')
  const websiteError = d.querySelector('div#websiteError')

  const validateDomain = event => {
    validWebsite = isURL(website.value, {
      require_valid_protocol: false,
      protocols: ['https', 'http'],
      require_protocol: false
    })

    validWebsite
      ? website.setAttribute('Style', 'box-shadow: inset 1px 1px 6px var(--green);')
      : website.value === ''
        ? website.setAttribute('Style', '')
        : website.setAttribute('Style', 'box-shadow: inset 1px 1px 6px var(--red);')

    validWebsite
      ? (websiteError.textContent = 'ツ') && (websiteError.setAttribute('Style', 'color: var(--black);'))
      : website.value !== ''
        ? (websiteError.textContent = '❗') && (websiteError.setAttribute('Style', 'color: var(--red);'))
        : (websiteError.textContent = '')

    // Test the updateOrderForm button.
    validAppName && validWebsite && validEmail
      ? userButton.removeAttribute('disabled')
      : userButton.setAttribute('disabled', 'true')
  }

  website.addEventListener('keyup', validateDomain, false)

  const validateDomainWithBackButton = () => {
    validateDomain()
  }

  setTimeout(validateDomainWithBackButton, 1)

  // Email address validations and spelling assistance
  const email = d.querySelector('input#email')
  const emailError = d.querySelector('div#emailError')
  const fakedomains = new Set(['example.com', 'test.com', 'goose.red'])

  const validateEmail = event => {
    validEmail = isEmail(email.value.trim()) && !fakedomains.has(email.value.trim().split("@")[1])

    validEmail
      ? email.setAttribute('Style', 'box-shadow: inset 1px 1px 6px var(--green);')
      : email.value === ''
        ? email.setAttribute('Style', '')
        : email.setAttribute('Style', 'box-shadow: inset 1px 1px 6px var(--red);')
    validEmail
      ? (emailError.textContent = 'ツ') && (emailError.setAttribute('Style', 'color: var(--black);'))
      : email.value !== ''
        ? (emailError.textContent = '❗') && (emailError.setAttribute('Style', 'color: var(--red);'))
        : (emailError.textContent = '')

    // Test the updateOrderForm button.
    validAppName && validWebsite && validEmail
      ? userButton.removeAttribute('disabled')
      : userButton.setAttribute('disabled', 'true')
  }

  email.addEventListener('keyup', validateEmail, false)

  const validateEmailWithBackButton = () => {
    validateEmail()
  }

  setTimeout(validateEmailWithBackButton, 1)

  // Assistance on email: Spelling check.
  const assistOnEmail = event => {
    Mailcheck.run({
      email: email.value,
      // domains: domains,                       // optional
      // topLevelDomains: topLevelDomains,       // optional
      // secondLevelDomains: secondLevelDomains, // optional
      // distanceFunction: superStringDistance,  // optional
      suggested: (suggestion) => {
        // console.log(suggestion)
        email.setAttribute('Style', 'box-shadow: inset 1px 1px 6px var(--orange);')
        emailError.textContent = 'Spelled ok?'
      }
    })
  }

  email.addEventListener('keyup', assistOnEmail, false)

  updateOrderForm.addEventListener(
    'submit',
    event => {
      loader.setAttribute('style', 'visibility: visible;')
      if (!validAppName || !validWebsite || !validEmail)
        event.preventDefault()
    },
    false
  )

})(document, window)
